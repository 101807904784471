* {
    font-family: 'Inter', sans-serif;
    outline: none;
}

.mapboxgl-control-container {
    display: none;
}

.blink {
    animation: blinking 1s infinite;
    transition: all 0.5s ease-in-out;
}

@keyframes blinking {
    0% {
        background-color: rgb(225, 178, 57)
    }
    50% {
        background-color: rgba(225, 178, 57, 0.5)
    }
    100% {
        background-color: rgba(225, 178, 57, 1)
    }
}
@-webkit-keyframes blinking {
    0% {
        background-color: rgba(225, 178, 57, 1)
    }
    50% {
        background-color: rgba(225, 178, 57, 0.5)
    }
    100% {
        background-color: rgba(225, 178, 57, 1)
    }
}

/* Map Marker */
.countingSetupMarker:before {
    content: ' ';
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    margin: 4px 8px;
    border: 3px solid royalblue;
}

.countingSetupMarker.activeCountingSetup:before {
    border-width: 4px;
    border-color: #FFBF00 !important;
}


.countingSetupMarker.active:before {
    background: green;
}

.countingSetupMarker {
    border-radius: 20px;
    padding-right: 12px;
    margin-right: -12px;
    color: transparent;
    font-size: 13px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.countingSetupMarker span {
    display: none;
}

.countingSetupMarker:hover {
    background: rgba(0,0,0,0.8);
    color: #fff;
}

.countingSetupMarker:hover span {
    display: inline-block;
}
